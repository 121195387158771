<template>
  <div>
    <loader2 v-if="load == true"></loader2>
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2 class="white" v-if="this.$route.params.id == 2">
          {{ $t("Tabs.cat1") }}
        </h2>
        <h2 class="white" v-if="this.$route.params.id == 3">
          {{ $t("Tabs.cat2") }}
        </h2>
        <h2 class="white" v-if="this.$route.params.id == 4">
          {{ $t("Tabs.cat3") }}
        </h2>
        <!-- <h2 class="white" v-if="this.$route.params.id == 5">{{  $t('Tabs.cat4') }}</h2> -->
        <h2 class="white" v-if="this.$route.params.id == 6">
          {{ $t("Tabs.cat5") }}
        </h2>
        <h2 class="white" v-if="this.$route.params.id == 7">
          {{ $t("Tabs.cat6") }}
        </h2>
      </div>
    </section>
    <section></section>
    <section id="mt_blog" class="top-stories" style="margin-bottom: 100px">
      <div class="blog_post_sec blog_post_inner">
        <div class="container">
          <div class="row">
            <div class="col-md-4" v-for="(post, i) in posts4" :key="i">
              <div class="blog-post_wrapper image-wrapper mar-bottom-30">
                <div class="blog-post-image" style="height: 260px">
                  <img
                    :src="'https://backend.scocenter.uz/storage/' + post.image"
                    alt="image"
                    height="260px"
                    class="img-responsive center-block post_img"
                  />
                </div>
                <div
                  class="post-content"
                  style="display: flex; flex-flow: row wrap; height: 240px"
                >
                  <h4>
                    <router-link :to="'/post-detail/' + post.id">{{
                      post[`title_${$i18n.locale}`]
                    }}</router-link>
                  </h4>
                  <div
                    class="item-meta"
                    style="width: 100%; align-self: flex-end"
                  >
                    <span style="font-size: 18px; color: #333a65">
                      {{ post.created_at | slice }}
                    </span>
                    <span
                      style="
                        font-size: 18px;
                        margin-right: 40px;
                        color: #333a65;
                        float: right;
                      "
                    >
                      <i class="fa fa-eye" style="margin-right: 7px"></i
                      >{{ post.views_count }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <category></category> -->
  </div>
</template>
<script>
import api from "../apis";
// import Category from '../components/category2'
export default {
  components: {
    // Category
  },
  data() {
    return {
      posts4: {},
      load: true,
    };
  },
  methods: {
    async makeRequest() {
      try {
        const res = await api
          .get("/posts/" + this.$route.params.id)
          .then((response) => {
            setTimeout(() => (this.load = false), 500);
            this.posts4 = response.data.details.sort((a, b) => b.id - a.id);
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}

@media screen and (prefers-color-scheme: dark) {
  body,
  input {
    color: #f1f1f1;
  }
  body {
    background: #171717;
  }
  .search-bar input {
    box-shadow: 0 0 0 0.4em #f1f1f1 inset;
  }
  .search-bar input:focus,
  .search-bar input:valid {
    background: #3d3d3d;
    box-shadow: 0 0 0 0.1em #3d3d3d inset;
  }
  .search-btn {
    background: #f1f1f1;
  }
}
a {
  background: linear-gradient(to right, #333a65, #333a65);
  color: #333a65;
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  background-size: 0px 3px;
  padding-bottom: 10px;
}
a:hover {
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}
@media (max-width: 991px) {
  #mt_blog .col-md-4 {
    height: auto;
  }
}
h2 {
  color: #161b3d;
  margin: 0;
  position: relative;
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 8px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7 !important;
  max-height: none !important;
}
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
.breadcrumb-outer {
  padding: 40px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 5px !important;
  font-size: 35px;
}
.d-flex {
  display: flex;
  flex-direction: column;
}
#mt_blog .blog-post_wrapper {
  overflow: inherit;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  box-shadow: 0 0 15px #cccccc60;
}

#mt_blog .blog-post_wrapper.image-wrapper:before {
  display: none;
}

#mt_blog .blog-post_wrapper .blog-post-image {
  overflow: hidden;
  position: relative;
}
#mt_blog .blog-post_wrapper .blog-post-image:before {
  content: "";
  background: #00000057;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#mt_blog .blog-post_wrapper.image-wrapper .post-content {
  position: relative;
  top: inherit;
  bottom: 0;
  padding: 15px;
}

#mt_blog .blog-post_wrapper h3 span {
  font-size: 30px;
  text-transform: uppercase;
  display: block;
}

section#mt_blog.category-list {
  background: url(/images/fitness/sliders/bg9.jpg) no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
}

section#mt_blog.category-list:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: #00000070;
  top: 0;
  height: 100%;
  width: 100%;
}

section#mt_blog.category-list .blog-post_wrapper.image-wrapper .post-content {
  background: #fff;
  padding: 15px;
}

section#mt_blog.category-list .row.slider-foods {
  margin: 0 -15px;
}
</style>